<template>
  <div class="schedule-card">
    <div class="schedule-card__post-tile">
      <PostTile :post="card" />
    </div>

    <div class="schedule-card__content">
      <div class="schedule-card__title">
        <span class="schedule-card__font schedule-card__font--title">
          {{ time }}
        </span>
      </div>

      <div class="schedule-card__description">
        <span class="schedule-card__font schedule-card__font--description">
          {{ $t("general.queueCard.description") }}
        </span>
      </div>

      <UiBadge>
        {{ $t("general.queueCard.statusScheduled") }}
      </UiBadge>
    </div>

    <div class="schedule-card__arrow">
      <button @click="$router.push('/posts/edit/' + postId)">
        <CIcon
          name="arrow-menu-to-right"
          class="schedule-card__icon schedule-card__icon--arrow"
        />
      </button>
    </div>
  </div>
</template>
<script>
import UiBadge from "@/features/ui/common/UiBadge.vue";
import waitRequest from "@/mixins/waitRequest";
import validationError from "@/mixins/validationError";
import { currencyFormat } from "@/tools/helpers";
import CIcon from "@/features/ui/CIcon.vue";
import PostTile from "@/features/containers/profile/PostTile.vue";

export default {
  name: "ScheduleCard",
  components: { PostTile, CIcon, UiBadge },
  mixins: [waitRequest, validationError],
  props: {
    card: {
      type: Object,
      required: true,
    },
  },
  computed: {
    time() {
      const date = new Date(this.card.published_at);

      const year = date.getFullYear().toString().slice(-2);
      const month = String(date.getMonth() + 1).padStart(2, '0'); // Месяцы начинаются с 0
      const day = String(date.getDate()).padStart(2, '0');
      const hours = String(date.getHours()).padStart(2, '0');
      const minutes = String(date.getMinutes()).padStart(2, '0');

      return `${day}/${month}/${year}  ·  ${hours}:${minutes}`;
    },
    postId() {
      return this.card.id;
    },
  },
  methods: {
    currencyFormat,
  },
};
</script>

<style lang="scss" scoped>
.schedule-card {
  border: 1.5px solid $app-gray-25;
  border-radius: 10px;
  width: 100%;
  padding: 16px 15px 19px;
  margin-top: 16px;
  display: flex;
  position: relative;

  &__post-tile {
    width: 69px;
    height: 69px;
    border-radius: 5px;
    overflow: hidden;
  }

  &__content {
    padding-left: 12px;
  }

  &__title {
  }

  &__description {
    margin-top: 2px;
  }

  &__status {
    margin-top: 8px;
  }

  &__arrow {
    padding: 15px;
    position: absolute;
    right: 20px;
    top: 50%;
    transform: translate3d(50%, -50%, 0);
  }

  &__font {
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 14px;
    color: $app-black;

    &--title {
      font-weight: 500;
      font-size: 14px;
    }

    &--description {
      font-weight: 400;
      font-size: 12px;
      color: $app-gray-18;
    }
  }

  &__icon {
    &--arrow {
      color: $app-gray-25;
      width: 17px;
      height: 17px;
      min-width: 17px;
      min-height: 17px;
    }
  }
}
</style>
