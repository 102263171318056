<template>
  <b-row class="statements-page">
    <b-col>
      <PageHeader :title="$t('general.queue.title')" >
        <button
          type="button"
          @click="$router.push('/posts/create')"
          class="base-button post-create__button post-create__button--header"
        >
          <span class="base-button__font">{{ $t("general.queue.addNew") }}</span>
        </button>
      </PageHeader>

      <InfinityScroll
        v-if="schedule.data && schedule.data.length"
        :items="schedule.data"
        :has-more="Boolean(schedule.next_page_url)"
        @rich-end="loadMore"
      >
        <template #default="{ item: card }">
          <ScheduleCard :card="card" />
        </template>
      </InfinityScroll>
    </b-col>
  </b-row>
</template>

<script>
import waitRequest from "@/mixins/waitRequest";
import validationError from "@/mixins/validationError";

import PageHeader from "@/layout/PageHeader.vue";

import { mapActions, mapGetters } from "vuex";
import InfinityScroll from "@/features/components/InfinityScroll.vue";
import ScheduleCard from "@/features/components/ScheduleCard.vue";
export default {
  name: "PageQueue",
  components: {
    InfinityScroll,
    PageHeader,
    ScheduleCard,
  },
  mixins: [waitRequest, validationError],
  computed: {
    ...mapGetters({
      user: "user",
      schedule: 'posts/schedule'
    }),
    referrals() {
      return { data: [{ tedt: "test" }] };
    },
    link() {
      return "https://join.fanflow.com/k82j2Nl";
    },
  },
  data() {
    return {};
  },
  mounted() {
    this.initState();
  },
  methods: {
    ...mapActions({
      fetchSchedule: "posts/fetchSchedule",
    }),
    initState() {
      this.fetchSchedule({ userId: this.$store.state.currentUser.id }).catch(this.checkErrors);
    },
    createQueryConfig(config) {
      return {
        page: this.schedule.current_page || 1,
        limit: this.schedule.per_page || 30,

        ...config,
      };
    },

    loadMore() {
      const queryConfig = this.createQueryConfig({ page:  this.schedule.current_page + 1 });
      return this.waitRequest(() => {
        return this.fetchSchedule(queryConfig).catch(this.checkErrors);
      });
    },
  },
};
</script>

<style scoped lang="scss">
.base-button {
  $parent: &;

  display: inline-flex;
  align-items: center;

  border-radius: em(100, 24);
  border: 1px solid $app-blue;
  background-color: $app-blue;
  padding: em(7, 24) em(15, 24);
  color: $white;

  &__font {
    color: $white;
    text-align: center;
    font-family: $font-default;
    font-size: em(12, 16);
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
}

</style>
